/**
 * Util function to check whether the regExp string or the userId value is any one of the default values:
 * '', '$^', '*' in case of regExp strings
 * 0, -2 in case of userId
 *
 * @param regExp RegExp string to be checked.
 * @param userId userId value to be checked.
 */
export function isDefaultFieldValue(regExp?: String, userId?: number): boolean {
  if (regExp !== undefined) {
    return regExp === '$^' || regExp === '*' || regExp === '';
  }
  if (userId !== undefined) {
    return userId === 0 || userId === -2;
  }
  return false;
}
