import type { FieldValues } from 'react-hook-form';
import FormBuilder from '../../form/FormBuilder/FormBuilder';
import { ButtonVariant } from '../../../components/common/Button/enums';
import type { FormAction, FormButton } from '../../../components/form/types';

class ModalFormBuilder<FormDataT extends FieldValues> extends FormBuilder<FormDataT> {
  /**
   * Adds submit action to the form.
   *
   * @param buttonSpec whether the action button displays loading indicator or not.
   */
  addSubmitAction(
    buttonSpec?: Omit<FormButton<FormDataT>, 'disabled' | 'size' | 'variant'>
  ): FormBuilder<FormDataT> {
    return super.addSubmitAction({
      ...buttonSpec,
      variant: ButtonVariant.PRIMARY,
      size: 'lg'
    });
  }

  /**
   * Adds reset action to the form.
   * @param buttonSpec form button specification for the action id.
   */
  addResetAction(
    buttonSpec?: Omit<FormButton<FormDataT>, 'disabled' | 'size' | 'variant'>
  ): FormBuilder<FormDataT> {
    return super.addResetAction({
      ...buttonSpec,
      variant: ButtonVariant.LIGHT,
      size: 'lg'
    });
  }

  /**
   * Adds cancel action to the form.
   * @param buttonSpec form button specification for the action id.
   */
  addCancelAction(
    buttonSpec?: Omit<FormButton<FormDataT>, 'disabled' | 'size' | 'variant'>
  ): FormBuilder<FormDataT> {
    return super.addCancelAction({
      ...buttonSpec,
      variant: ButtonVariant.LIGHT,
      size: 'lg'
    });
  }

  /**
   * Adds form action to the form.
   * @param actionSpec form action specification for the action id.
   */
  addFormAction(actionSpec?: FormAction<FormDataT>): FormBuilder<FormDataT> {
    return super.addFormAction({
      ...actionSpec,
      buttonSpec: {
        ...actionSpec?.buttonSpec,
        size: 'lg'
      }
    });
  }
}

export default ModalFormBuilder;
