import { useApolloClient } from '@apollo/client';
import Button from '@aurora/shared-client/components/common/Button/Button';
import {
  ActionButtonVariant,
  ButtonVariant,
  LoadingButtonVariant
} from '@aurora/shared-client/components/common/Button/enums';
import {
  ToastAlertVariant,
  ToastVariant
} from '@aurora/shared-client/components/common/ToastAlert/enums';
import type ToastProps from '@aurora/shared-client/components/common/ToastAlert/ToastAlertProps';
import useToasts from '@aurora/shared-client/components/context/ToastContext/useToasts';
import useMutationWithTracing from '@aurora/shared-client/components/useMutationWithTracing';
import { EndUserComponent } from '@aurora/shared-types/pages/enums';
import dynamic from 'next/dynamic';
import React, { useCallback, useState } from 'react';
import { Dropdown, useClassNameMapper } from 'react-bootstrap';
import type {
  RemoveBanRuleMutation,
  RemoveBanRuleMutationVariables
} from '../../../types/graphql-types';
import useTranslation from '../../useTranslation';
import bansQuery from './../Bans.query.graphql';
import localStyles from './DeleteBanAction.module.pcss';
import removeBanRule from './RemoveBanRule.mutation.graphql';

const ConfirmationDialog = dynamic(
  () => import('@aurora/shared-client/components/common/ConfirmationDialog/ConfirmationDialog'),
  { ssr: false }
);

interface DeleteBanActionProps {
  /**
   * ID of the ban.
   */
  banId: string;
  /**
   * Variant of the action button.
   */
  actionButtonVariant?: ActionButtonVariant;
  /**
   * The class name(s) applied to the component element.
   */
  className?: string;
}

/**
 * Delete ban action.
 *
 * @param banId id of the ban to be deleted
 * @author Prashanth TR
 */
const DeleteBanAction: React.FC<React.PropsWithChildren<DeleteBanActionProps>> = ({
  banId,
  actionButtonVariant = ActionButtonVariant.DROP_DOWN_BUTTON,
  className
}) => {
  const { formatMessage, loading: textLoading } = useTranslation(
    EndUserComponent.DELETE_BAN_ACTION
  );
  const { addToast } = useToasts();
  const [confirmationDialog, setConfirmationDialog] = useState(false);
  const client = useApolloClient();
  const cx = useClassNameMapper(localStyles);

  const [removeBanRuleMutation] = useMutationWithTracing<
    RemoveBanRuleMutation,
    RemoveBanRuleMutationVariables
  >(module, removeBanRule);

  /**
   * Renders success feedback
   */
  const renderSuccessFeedback = useCallback((): void => {
    const toastProps: ToastProps = {
      toastVariant: ToastVariant.FLYOUT,
      alertVariant: ToastAlertVariant.SUCCESS,
      title: formatMessage('removeBanRuleSuccess.title'),
      message: formatMessage('removeBanRuleSuccess.message'),
      autohide: true,
      id: 'Delete-ban-success'
    };
    addToast(toastProps);
  }, [addToast, formatMessage]);

  /**
   * Build toast error
   */
  const renderErrorFeedback = useCallback((): void => {
    const toastProps: ToastProps = {
      toastVariant: ToastVariant.BANNER,
      alertVariant: ToastAlertVariant.DANGER,
      title: formatMessage('removeBanRuleFailed.title'),
      message: formatMessage('removeBanRuleFailed.message'),
      autohide: true,
      id: 'RemoveBanError'
    };
    addToast(toastProps);
  }, [addToast, formatMessage]);

  /**
   * Remove ban mutation call
   */
  const removeBanMutation = useCallback(async () => {
    const { data, errors } = await removeBanRuleMutation({
      variables: {
        banId
      },
      refetchQueries: [
        {
          query: bansQuery
        }
      ]
    });
    client.cache.evict({
      id: 'ROOT_QUERY',
      fieldName: 'bans',
      broadcast: false
    });

    if (errors || data?.removeBan?.errors) {
      renderErrorFeedback();
    } else {
      renderSuccessFeedback();
    }
    setConfirmationDialog(null);
  }, [removeBanRuleMutation, banId, client.cache, renderErrorFeedback, renderSuccessFeedback]);

  /**
   * Renders confirmation dialog
   */
  const renderConfirmationDialog = (): React.ReactElement => {
    return (
      <ConfirmationDialog
        show={confirmationDialog}
        onHide={(): void => setConfirmationDialog(false)}
        onSubmit={() => removeBanMutation()}
        onCancel={(): void => setConfirmationDialog(false)}
        titleText={formatMessage('confirmDeleteBanDialog.title')}
        bodyText={formatMessage('confirmDeleteBanDialog.body')}
        submitButtonType={LoadingButtonVariant.LOADING_BUTTON}
        submitButtonText={formatMessage('confirmDeleteBanDialog.submit')}
        cancelButtonText={formatMessage('confirmDeleteBanDialog.cancel')}
      />
    );
  };

  if (textLoading) {
    return null;
  }

  return (
    <>
      {actionButtonVariant === ActionButtonVariant.DROP_DOWN_BUTTON ? (
        <Dropdown.Item
          onClick={() => setConfirmationDialog(true)}
          className={cx(className, 'lia-action-delete-ban')}
        >
          {formatMessage('deleteDropDownText')}
        </Dropdown.Item>
      ) : (
        <Button
          className={cx(className)}
          variant={ButtonVariant.DANGER}
          size="lg"
          tabIndex={0}
          onClick={() => {
            setConfirmationDialog(true);
          }}
        >
          {formatMessage('deleteButton')}
        </Button>
      )}
      {confirmationDialog && renderConfirmationDialog()}
    </>
  );
};

export default DeleteBanAction;
